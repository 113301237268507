<script setup lang="ts">
const { $qrHuntRouter, $headerBackTo } = useNuxtApp()
$headerBackTo({ routeType: 'qrhunt', name: 'scan' } as QRHuntRoute)
const log = createLog('app:qrhunt:scanFailure')
</script>

<template>
    <div class="page-scan-failure">
        <Icon
            name="circle-stop-48x"
            fluid
            circle="#E59EB2"
            class="page-scan-failure__icon"
        />

        <RichText
            class="page-scan-failure__text rich-text--compact"
            :text="$t('page.routeScanFailure.introText')"
        />

        <ButtonGroup class="page-scan-failure__buttons">
            <AppButton
                fluid
                @click="
                    () => {
                        $qrHuntRouter.push('scan')
                    }
                "
            >
                {{ $t('page.routeScanFailure.buttonLabel.back') }}
            </AppButton>
        </ButtonGroup>
    </div>
</template>

<style lang="scss">
.page-scan-failure {
    height: 100%;
    display: grid;
    grid-template-rows: auto 30px auto minmax(48px, 1fr) auto;
    grid-template-areas:
        'icon   '
        '.      '
        'text   '
        '.      '
        'buttons';

    &__icon {
        grid-area: icon;
        width: min(100%, 80px);
    }

    &__text {
        grid-area: text;
    }

    &__buttons {
        grid-area: buttons;
    }
}
</style>
